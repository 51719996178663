// extracted by mini-css-extract-plugin
export var column = "carousel-module--column--FIwBv";
export var dateOfPost = "carousel-module--dateOfPost--za4VG";
export var description = "carousel-module--description--O+mry";
export var img = "carousel-module--img--kUrac";
export var item = "carousel-module--item--tkxdg";
export var list = "carousel-module--list--FfoKH";
export var navigationButton = "carousel-module--navigationButton--cous+";
export var navigationIcon = "carousel-module--navigationIcon--GXL+2";
export var navigationOfSlider = "carousel-module--navigationOfSlider--OCHAB";
export var sliderItemsWrap = "carousel-module--sliderItemsWrap--vHlZ9";
export var sliderWrap = "carousel-module--sliderWrap--y3Gwy";
export var title = "carousel-module--title--8zOM6";